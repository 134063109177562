import { useCreateProposalMutation as useBaseMutations } from '../../../graphql/generated';
import { useAppStore } from '../../../main/store';
import {
  checkGraphqlError,
  currency_disabled_errors,
} from '../../../services/apollo/utils';

export const useCreateProposalMutation = (id?: string) => {
  const setRefundCurrencyDisabled = useAppStore(
    (state) => state.setRefundCurrencyDisabled
  );

  const [createProposal, { loading }] = useBaseMutations({
    onError: (error) => {
      if (error.graphQLErrors) {
        const isCurrencyDisabledError = checkGraphqlError(
          error.graphQLErrors,
          currency_disabled_errors
        );

        if (isCurrencyDisabledError) {
          setRefundCurrencyDisabled(true);
        }
      }
    },
    update(cache, { data }) {
      if (!data || !data.refund || !data.refund.createProposal || !id) {
        return;
      }

      const {
        refund: {
          createProposal: { convertedAmount, exchangeRate, expiresAt },
        },
      } = data;

      cache.modify({
        id: cache.identify({ __typename: 'Refund', id }),
        fields: {
          proposal() {
            return {
              __typename: 'RefundProposal',
              convertedAmount,
              exchangeRate,
              expiresAt,
            };
          },
        },
      });
    },
  });

  return { createProposal, loading };
};

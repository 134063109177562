import cx from 'classnames';
import { useState } from 'react';

import styles from './Header.module.css';

import {
  Center,
  Container,
  Hamburger,
  MobileNavMenu,
  Text,
} from '@frontend/ui-library';

interface HeaderProps {
  className?: string;
  id?: string;
}

export default function Header({ className, id }: HeaderProps): JSX.Element {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className={cx(styles['base'], className)}>
      <Container>
        <div className={styles['main-bar']}>
          <div className={styles['left-column']}>
            {id && (
              <Text
                className={styles['payment-id']}
                size="tiny-callout"
                color="royal-100"
              >
                Refund ID: <br />
                {id}
              </Text>
            )}
          </div>
          <div className={styles['middle-column']}>
            <h1 className={styles['title']}>Refund center</h1>
          </div>
          <div className={styles['right-column']}>
            <Hamburger
              className={styles['hamburger']}
              onClick={() => setMenuOpen(true)}
            />
          </div>
        </div>
      </Container>
      {menuOpen && (
        <MobileNavMenu onRequestClose={() => setMenuOpen(false)}>
          <Center direction="column">
            {!!id && (
              <Text
                as="div"
                transform="uppercase"
                size="tiny-callout"
                color="royal-100"
                align="center"
              >
                Refund ID: <br /> {id}
              </Text>
            )}
          </Center>
        </MobileNavMenu>
      )}
    </header>
  );
}

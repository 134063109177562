import { Text, Box, BoxStyleProps } from '../..';
import cx from 'classnames';
import { ReactNode } from 'react';

import styles from './WidgetStatus.module.css';

interface WidgetStatusProps extends BoxStyleProps {
  className?: string;
  emoji?: string;
  title?: string;
  description?: ReactNode;
}

export default function WidgetStatus({
  className,
  emoji,
  title,
  description,
  ...otherProps
}: WidgetStatusProps): JSX.Element {
  return (
    <Box className={cx(styles['base'], className)} {...otherProps}>
      <div className={styles['emoji']} aria-hidden>
        {emoji}
      </div>
      <Text
        size="large"
        color="neutral-900"
        weight="600"
        data-test-id="widgetStatus"
      >
        {title}
      </Text>
      {description && (
        <Text size="small" color="neutral-600">
          {description}
        </Text>
      )}
    </Box>
  );
}

import {
  PhoneMockup,
  PhoneMockupBodyLayout,
  PhoneMockupLayout,
  WidgetHeader,
  WidgetHeaderContent,
  WidgetStatus,
} from '@frontend/ui-library';

export default function RefundUnavailable(): JSX.Element {
  return (
    <PhoneMockupLayout>
      <PhoneMockup
        header={
          <WidgetHeader color="error">
            <WidgetHeaderContent title="Refund unavailable" />
          </WidgetHeader>
        }
      >
        <PhoneMockupBodyLayout
          bodyCentered
          body={
            <WidgetStatus
              emoji="😕"
              title="Refund unavailable"
              description={
                <>
                  Refunds from this business are currently not possible. You can
                  try again later or contact the business directly if you need
                  any more information.
                </>
              }
            />
          }
        />
      </PhoneMockup>
    </PhoneMockupLayout>
  );
}

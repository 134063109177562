import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  auth_errors,
  checkGraphqlError,
  clearItem,
  getItem,
  refunds_disabled_errors,
} from './utils';

import { onError } from '@apollo/client/link/error';
import { useAppStore } from '../../main/store';

export const SESSION_STORAGE_AUTH_KEY = 'authToken';

export const IS_DEV = process.env['NODE_ENV'] === 'development';

export const createApiUrl = () => {
  const [, ...domain] = window.location.hostname.split('.');

  return ['resolutioncenter', 'api', ...domain].join('.');
};

const getApiUrl = () => {
  if (IS_DEV) {
    return 'https://resolutioncenter.api.dev.crypto.xmoney.com/api';
  }

  return `https://${createApiUrl()}/api`;
};

const httpLink = new HttpLink({
  uri: getApiUrl(),
});

const authLink = setContext((_, { headers }) => {
  const token = getItem(SESSION_STORAGE_AUTH_KEY);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const isAuthError = checkGraphqlError(graphQLErrors, auth_errors);
    const isRefundDisabledError = checkGraphqlError(
      graphQLErrors,
      refunds_disabled_errors
    );

    if (isRefundDisabledError) {
      useAppStore.getState().setRefundCurrencyDisabled(true);
    }

    if (isAuthError) {
      useAppStore.getState().setIsAuthenticated(false);
      clearItem(SESSION_STORAGE_AUTH_KEY);
      return;
    }
  }
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink.concat(httpLink)]),
});

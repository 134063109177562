import {
  ComponentProps,
  useId,
  forwardRef,
  useRef,
  Ref,
  ReactNode,
} from 'react';
import cx from 'classnames';

import styles from './Checkbox.module.css';
import Icon from '../Icon/Icon';

import { ReactComponent as CheckmarkSvg } from '../../../../icons/src/lib/checkmark.svg';
import { ReactComponent as DashSvg } from '../../../../icons/src/lib/dash.svg';
import Box, { BoxStyleProps, extractBoxStyleProps } from '../Box';
import Text from '../Text';

export interface CheckboxProps extends ComponentProps<'input'>, BoxStyleProps {
  className?: string;
  label?: ReactNode;
  indeterminate?: boolean;
}

const Checkbox = forwardRef(
  (
    {
      className,
      label,
      checked,
      indeterminate,
      id: idProp,
      ...otherProps
    }: CheckboxProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const generatedId = useId();
    const id = useRef(idProp || generatedId);
    const IconComponent = indeterminate ? DashSvg : CheckmarkSvg;
    const [boxStyleProps, inputProps] = extractBoxStyleProps(otherProps);

    return (
      <Box className={cx(styles['base'], className)} {...boxStyleProps}>
        <div className={styles['checkbox']}>
          <input
            {...inputProps}
            ref={ref}
            className={styles['input']}
            type="checkbox"
            checked={indeterminate || checked}
            id={id.current}
          />
          <div className={styles['box']}>
            <Icon className={styles['icon']} component={<IconComponent />} />
          </div>
        </div>
        {label && (
          <Text
            size="small"
            as="label"
            color="neutral-600"
            htmlFor={id.current}
          >
            {label}
          </Text>
        )}
      </Box>
    );
  }
);

export default Checkbox;

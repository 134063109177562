import cx from 'classnames';
import { PropsWithChildren } from 'react';

import Box, { BoxStyleProps } from '../Box';

import styles from './Center.module.css';

interface CenterProps extends BoxStyleProps {
  className?: string;
  inline?: boolean;
  direction?: 'row' | 'column';
}

export default function Center({
  className,
  inline,
  children,
  direction = 'row',
  ...otherProps
}: PropsWithChildren<CenterProps>): JSX.Element {
  return (
    <Box
      className={cx(
        styles['base'],
        { [styles['base--inline']]: inline },
        { [styles['base--direction-column']]: direction !== 'row' },
        className
      )}
      {...otherProps}
    >
      {children}
    </Box>
  );
}

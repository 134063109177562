import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';

import App from './app/app';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './app/services/apollo';

Sentry.init({
  dsn: process.env['NX_SENTRY_DSN_REFUNDCENTER'],
  environment: process.env['NX_ENVIRONMENT'],
  release: process.env['NX_RELEASE_NAME'],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </StrictMode>
);

import {
  cloneElement,
  ComponentPropsWithoutRef,
  FocusEvent,
  forwardRef,
  isValidElement,
  ReactNode,
  useId,
  useRef,
  Ref,
  useState,
} from 'react';
import cx from 'classnames';

import Box, { BoxStyleProps, extractBoxStyleProps } from '../Box';

import styles from './Input.module.css';

export interface InputProps
  extends ComponentPropsWithoutRef<'input'>,
    BoxStyleProps {
  className?: string;
  label?: string;
  leftIcon?: ReactNode;
  error?: boolean;
  rightButton?: ReactNode;
}

const Input = forwardRef(
  (
    {
      className,
      label,
      error,
      leftIcon,
      rightButton,
      placeholder,
      id: idProp,
      onFocus,
      onBlur,
      ...otherProps
    }: InputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const generatedId = useId();
    const id = useRef(idProp || generatedId);
    const [focused, setFocused] = useState(false);
    const [boxStyleProps, inputProps] = extractBoxStyleProps(otherProps);

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(true);

      onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(false);

      onBlur?.(e);
    };

    return (
      <Box
        className={cx(
          styles['base'],
          { [styles['base--with-label']]: !!label },
          { [styles['base--with-left-icon']]: !!leftIcon },
          { [styles['is-focused']]: focused },
          { [styles['has-error']]: error },
          className
        )}
        {...boxStyleProps}
      >
        <div className={styles['main']}>
          {leftIcon && (
            <div className={styles['left-icon-wrapper']}>{leftIcon}</div>
          )}
          <div className={styles['input-wrapper']}>
            <input
              {...inputProps}
              id={id.current}
              ref={ref}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={label ? ' ' : placeholder}
              className={styles['input']}
            />
            {!!label && (
              <label className={styles['label']} htmlFor={id.current}>
                {label}
              </label>
            )}
          </div>
          {isValidElement(rightButton) &&
            cloneElement(rightButton, {
              error,
              ...(rightButton as JSX.Element)?.props,
            })}
        </div>
      </Box>
    );
  }
);

export default Input;

import { useMediaQuery } from 'react-responsive';

export enum SCREEN_SIZE {
  XS,
  SM,
  MD,
  LG,
  XL,
  '2XL',
}

function useResponsive(): SCREEN_SIZE {
  const isXS = useMediaQuery({
    maxWidth: 639,
  });

  const isSM = useMediaQuery({
    minWidth: 640,
    maxWidth: 767,
  });

  const isMD = useMediaQuery({
    minWidth: 768,
    maxWidth: 1023,
  });

  const isLG = useMediaQuery({
    minWidth: 1024,
    maxWidth: 1279,
  });

  const isXL = useMediaQuery({
    minWidth: 1280,
    maxWidth: 1535,
  });

  switch (true) {
    case isXS:
      return SCREEN_SIZE.XS;
    case isSM:
      return SCREEN_SIZE.SM;
    case isMD:
      return SCREEN_SIZE.MD;
    case isLG:
      return SCREEN_SIZE.LG;
    case isXL:
      return SCREEN_SIZE.XL;
    default:
      return SCREEN_SIZE['2XL'];
  }
}

export default useResponsive;

import { gql } from '@apollo/client';

export const ProposalFields = gql`
  fragment ProposalFields on RefundProposal {
    convertedAmount {
      value
      currency
      blockchain
    }
    exchangeRate {
      from
      rate
      to
    }
    expiresAt
  }
`;

import {
  ComponentPropsWithoutRef,
  ElementType,
  PropsWithChildren,
  ReactNode,
} from 'react';
import cx from 'classnames';

import styles from './Button.module.css';

import Loader from '../Loader';
import Box, { BoxStyleProps } from '../Box';

const DEFAULT_ELEMENT = 'button';

interface ButtonOwnProps<C> {
  as?: C;
  className?: string;
  contentClassName?: string;
  size?: 'small' | 'medium' | 'large';
  variant?:
    | 'primary'
    | 'secondary'
    | 'primary-outline'
    | 'white-outline'
    | 'ghost'
    | 'danger';
  error?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  loading?: boolean;
  fullWidth?: boolean;
}

export type ButtonProps<C extends ElementType = typeof DEFAULT_ELEMENT> =
  ButtonOwnProps<C> & ComponentPropsWithoutRef<C> & BoxStyleProps;

export default function Button<C extends ElementType = typeof DEFAULT_ELEMENT>({
  className,
  as,
  contentClassName,
  size = 'large',
  variant = 'primary',
  error,
  children,
  leftIcon,
  rightIcon,
  disabled,
  loading,
  fullWidth,
  ...otherProps
}: PropsWithChildren<ButtonProps<C>>): JSX.Element {
  const disabledProp = disabled || error || loading;
  const Component = as || DEFAULT_ELEMENT;

  return (
    <Box
      as={Component}
      className={cx(
        styles['base'],
        styles[`base--size-${size}`],
        styles[`base--variant-${variant}`],
        { [styles['base--full-width']]: fullWidth },
        { [styles['is-error']]: error },
        className
      )}
      disabled={disabledProp}
      {...otherProps}
    >
      {leftIcon && (
        <div
          className={cx(styles['icon-wrapper'], styles['icon-wrapper--left'])}
        >
          {leftIcon}
        </div>
      )}
      <div className={cx(styles['content'], contentClassName)}>{children}</div>
      {(loading || rightIcon) && (
        <div
          className={cx(styles['icon-wrapper'], styles['icon-wrapper--right'])}
        >
          {loading ? <Loader data-test-id="loader" /> : rightIcon}
        </div>
      )}
    </Box>
  );
}

import cx from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './Layout.module.css';
import Footer from '../Footer/Footer';
import Loader from '../Loader/Loader';

interface LayoutProps {
  className?: string;
  loading?: boolean;
  header?: JSX.Element;
}

export default function Layout({
  className,
  header,
  loading,
  children,
}: PropsWithChildren<LayoutProps>): JSX.Element {
  return (
    <div className={cx(styles['base'], className)}>
      {header}
      {loading ? (
        <div className={styles['loader-wrapper']}>
          <Loader size={48} color="white" />
        </div>
      ) : (
        <div className={styles['main']}>
          <div className={styles['widget-wrapper']}>{children}</div>
        </div>
      )}
      <Footer className={styles['footer']} />
    </div>
  );
}

import {
  PhoneMockup,
  PhoneMockupBodyLayout,
  PhoneMockupLayout,
  WidgetHeader,
  WidgetHeaderContent,
  WidgetStatus,
} from '@frontend/ui-library';

export default function ProcessingRefund(): JSX.Element {
  return (
    <PhoneMockupLayout>
      <PhoneMockup
        header={
          <WidgetHeader color="success">
            <WidgetHeaderContent title="Processing refund" loading />
          </WidgetHeader>
        }
      >
        <PhoneMockupBodyLayout
          body={
            <WidgetStatus
              mt="160"
              emoji="💸"
              title="Your refund is on the way!"
              description={
                <>
                  You'll get an email once the transaction is completed. <br />
                  Thank you for using xMoney!
                </>
              }
            />
          }
        />
      </PhoneMockup>
    </PhoneMockupLayout>
  );
}

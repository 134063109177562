import { GraphQLErrors } from '@apollo/client/errors';

export const getItem = (key: string) => sessionStorage.getItem(key);
export const clearItem = (key: string) => sessionStorage.removeItem(key);
export const storeItem = (key: string, value: string) =>
  sessionStorage.setItem(key, value);

export const refunds_disabled_errors = ['merchant_refunds_disabled'];
export const auth_errors = ['Unauthorized', 'invalid_credentials'];
export const currency_disabled_errors = [
  'currency_disabled',
  'no match of right hand side value: {:error, :currency_disabled}',
];

export const checkGraphqlError = (
  graphQLErrors: GraphQLErrors,
  errorCodes: string[]
) => {
  return graphQLErrors.some((graphQLError) => {
    return errorCodes.includes(graphQLError.message);
  });
};

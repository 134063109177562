import cx from 'classnames';
import { cloneElement, ReactNode, isValidElement, ReactElement } from 'react';
import { Colors } from '../theme/types';

import styles from './Icon.module.css';

export interface IconProps {
  className?: string;
  size?: number | string;
  'aria-hidden'?: boolean | 'true' | 'false';
  component: ReactNode;
  color?: Colors;
  'aria-label'?: string;
  'data-test-id'?: string;
  fillWithCurrentColor?: boolean;
}

export default function Icon({
  className,
  component,
  size = '1em',
  'aria-hidden': ariaHidden = true,
  'aria-label': ariaLabel,
  'data-test-id': dataTestId,
  color,
  fillWithCurrentColor = true,
}: IconProps): JSX.Element | null {
  if (!isValidElement(component)) return null;

  return cloneElement(component as ReactElement, {
    className: cx(className, styles['base']),
    width: size,
    height: size,
    'aria-hidden': ariaHidden,
    'data-test-id': dataTestId,
    'aria-label': ariaLabel,
    fill: fillWithCurrentColor ? 'currentColor' : 'none',
    style: {
      color: color ? `var(--u-color-${color})` : undefined,
    } as Partial<CSSStyleDeclaration>,
  });
}

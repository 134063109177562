import cx from 'classnames';
import { ComponentPropsWithoutRef, ElementType } from 'react';

import Box from '../Box';
import { BoxOwnProps } from '../Box/Box';
import { Colors, Size, Weight } from '../theme/types';

import styles from './Text.module.css';

const DEFAULT_ELEMENT = 'span';

interface TextOwnProps<C> extends BoxOwnProps<C> {
  as?: C;
  className?: string;
  size?:
    | 'inherit'
    | Size
    | 'medium-callout'
    | 'base-callout'
    | 'small-callout'
    | 'tiny-callout'
    | 'miniscule-callout'
    | 'superMiniscule-callout';
  decoration?: 'none' | 'underline';
  truncated?: boolean;
  transform?: 'capitalize' | 'uppercase';
  callout?: boolean;
  align?: 'left' | 'center' | 'right';
  color?: Colors | 'inherit' | 'none';
  weight?: Weight;
}

export type TextProps<C extends ElementType = typeof DEFAULT_ELEMENT> =
  TextOwnProps<C> & ComponentPropsWithoutRef<C>;

export default function Text<C extends ElementType = typeof DEFAULT_ELEMENT>({
  className,
  as,
  size = 'base',
  color = 'inherit',
  weight = '400',
  truncated,
  align,
  children,
  decoration,
  transform,
  ...otherProps
}: TextProps<C>): JSX.Element {
  const colorStyle = {
    color:
      color !== 'none'
        ? color === 'inherit'
          ? 'inherit'
          : `var(--u-color-${color})`
        : undefined,
  };

  const combinedStyles = { ...colorStyle, ...(otherProps?.['style'] || {}) };

  return (
    <Box
      as={as || DEFAULT_ELEMENT}
      className={cx(
        styles['base'],
        styles[`base--size-${size}`],
        styles[`base--weight-${weight}`],
        styles[`base--decoration-${decoration}`],
        styles[`base--transform-${transform}`],
        styles[`base--align-${align}`],
        { [styles['base--truncated']]: truncated },
        className
      )}
      {...otherProps}
      style={combinedStyles}
    >
      {children}
    </Box>
  );
}

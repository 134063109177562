import { useCallback, useEffect, useRef } from 'react';
import { useSignInMutation } from '../graphql/generated';
import { getQueryParams, isValidQueryParams } from './utils';
import { SESSION_STORAGE_AUTH_KEY } from '../services/apollo';
import {
  auth_errors,
  checkGraphqlError,
  getItem,
  storeItem,
} from '../services/apollo/utils';

interface UseAuthProps {
  setAuthentication: (state: boolean) => void;
  onAuthError: () => void;
}

function removeUrlQueryParameters() {
  const cleanUrl = window.location.origin + window.location.pathname;
  window.history.replaceState({}, document.title, cleanUrl);
}

export function useAuth({ setAuthentication, onAuthError }: UseAuthProps) {
  const [signIn, { loading }] = useSignInMutation({
    onCompleted: (data) => {
      if (data.signIn?.token) {
        storeItem(SESSION_STORAGE_AUTH_KEY, data.signIn.token);
        setAuthentication(true);
        removeUrlQueryParameters();
      }
    },
    onError: (error) => {
      const isAuthError = checkGraphqlError(error.graphQLErrors, auth_errors);

      if (isAuthError) {
        onAuthError();
      }
    },
  });

  const signInUser = useCallback(async () => {
    const { email, referenceCode } = getQueryParams();

    if ((!email || !referenceCode) && getItem(SESSION_STORAGE_AUTH_KEY)) {
      setAuthentication(true);
      return;
    }

    const isValid = isValidQueryParams({ email, referenceCode });

    if (!isValid || !email || !referenceCode) {
      onAuthError();
      return;
    }

    signIn({
      variables: {
        email,
        referenceCode,
      },
    });
  }, [signIn, onAuthError, setAuthentication]);

  // prevent react strict double useEffect run.
  const sentRequestRef = useRef(false);

  useEffect(() => {
    if (sentRequestRef.current) {
      return;
    }

    signInUser();
    sentRequestRef.current = true;
  }, [signInUser]);

  return {
    loading,
  };
}

import {
  Button,
  Icon,
  PhoneMockup,
  PhoneMockupBodyLayout,
  PhoneMockupLayout,
  WidgetHeader,
  WidgetHeaderContent,
  WidgetStatus,
} from '@frontend/ui-library';

import { ReactComponent as LinkSvg } from '@frontend/icons/external-link-2.svg';
import { links } from '../../utils/links';

export default function RefundNotFound(): JSX.Element {
  return (
    <PhoneMockupLayout>
      <PhoneMockup
        header={
          <WidgetHeader color="error">
            <WidgetHeaderContent title="Refund not found" />
          </WidgetHeader>
        }
      >
        <PhoneMockupBodyLayout
          bodyCentered
          body={
            <WidgetStatus
              emoji="😕"
              title="Couldn’t find your refund"
              description={
                <>
                  The refund you’re looking for doesn’t exist. <br />
                  Double-check the URL and try again.
                </>
              }
            />
          }
          footer={
            <Button
              as="a"
              size="large"
              href={links.support.contact_support}
              target="_blank"
              rel="noopener noreferrer"
              variant="ghost"
              rightIcon={<Icon component={<LinkSvg />} />}
            >
              Contact Support
            </Button>
          }
        />
      </PhoneMockup>
    </PhoneMockupLayout>
  );
}

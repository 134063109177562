import {
  PhoneMockup,
  PhoneMockupBodyLayout,
  PhoneMockupLayout,
  WidgetHeader,
  WidgetHeaderContent,
  WidgetStatus,
} from '@frontend/ui-library';

export default function RefundTemporarilyUnavailable(): JSX.Element {
  return (
    <PhoneMockupLayout>
      <PhoneMockup
        header={
          <WidgetHeader color="warning">
            <WidgetHeaderContent title="temporarily unavailable" />
          </WidgetHeader>
        }
      >
        <PhoneMockupBodyLayout
          bodyCentered
          body={
            <WidgetStatus
              emoji="😕"
              title="This refund is temporarily unavailable"
              description={
                <>
                  We’ve suspended transactions of the currency you used when
                  paying and can’t process your request at this time. Please try
                  again later.
                </>
              }
            />
          }
        />
      </PhoneMockup>
    </PhoneMockupLayout>
  );
}

import { createContext } from 'react';
import { ToastProps } from '../Toast';

type OpenParams = Pick<
  ToastProps,
  'status' | 'message' | 'title' | 'autoHideDuration' | 'className'
>;

interface ToastContextProps {
  key: number;
  toastProps: ToastProps | null;
  open: (props: OpenParams) => void;
  close: () => void;
}

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

export default ToastContext;

import { z } from 'zod';

export function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  const email = params.get('email');
  const referenceCode = params.get('referenceCode');
  return { email, referenceCode };
}

interface QueryParams {
  email?: string | null;
  referenceCode?: string | null;
}

export function isValidQueryParams({ email, referenceCode }: QueryParams) {
  if (!email || !referenceCode) {
    return false;
  }

  const { success } = z
    .object({
      email: z.string().email(),
      referenceCode: z.string(),
    })
    .safeParse({ email, referenceCode });

  if (!success) {
    return false;
  }

  return true;
}

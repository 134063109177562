import {
  Layout,
  PhoneMockupLayoutProvider,
  ToastProvider,
} from '@frontend/ui-library';

import { useCallback, useState } from 'react';
import { RefundStatus } from '../../types';
import Header from '../components/Header/Header';
import { useGetRefundStatusQuery } from '../graphql/generated';

import { AnimatePresence } from 'framer-motion';
import ProcessingRefund from '../pages/ProcessingRefund/ProcessingRefund';
import RefundComplete from '../pages/RefundComplete/RefundComplete';
import RefundNotFound from '../pages/RefundNotFound/RefundNotFound';
import RefundRequest from '../pages/RefundRequest/RefundRequest';
import RefundUnavailable from '../pages/RefundUnavailable/RefundUnavailable';
import { useAuth } from './useAuth';
import RefundTemporarilyUnavailable from '../pages/RefundTemporarilyUnavailable/RefundTemporarilyUnavailable';
import { useAppStore } from './store';

export enum Screens {
  REFUND_PROCESSING,
  REFUND_REQUEST,
  REFUND_COMPLETE,
  NOT_FOUND,
}

export default function Main(): JSX.Element {
  const [notFound, setNotFound] = useState(false);

  const setAuthentication = useAppStore((state) => state.setIsAuthenticated);
  const isAuthenticated = useAppStore((state) => state.isAuthenticated);
  const isRefundDisabled = useAppStore((state) => state.isRefundDisabled);
  const isRefundCurrencyDisabled = useAppStore(
    (state) => state.isRefundCurrencyDisabled
  );
  const { data } = useGetRefundStatusQuery({
    skip: !isAuthenticated,
    pollInterval: 1000 * 60 * 1, // 1 minute,
  });

  const handleAuthError = useCallback(() => {
    setNotFound(true);
  }, [setNotFound]);

  const { loading } = useAuth({
    setAuthentication,
    onAuthError: handleAuthError,
  });

  const refundStatus = data?.refund?.status || undefined;

  return (
    <ToastProvider>
      <Layout loading={loading} header={<Header />}>
        <PhoneMockupLayoutProvider>
          <AnimatePresence mode="wait">
            {getScreen({
              refundStatus: refundStatus,
              notFound,
              notAvailable: isRefundDisabled,
              temporaryUnavailable: isRefundCurrencyDisabled,
            })}
          </AnimatePresence>
        </PhoneMockupLayoutProvider>
      </Layout>
    </ToastProvider>
  );
}

interface GetScreenProps {
  refundStatus?: RefundStatus;
  notFound?: boolean;
  notAvailable?: boolean;
  temporaryUnavailable?: boolean;
}

function getScreen({
  refundStatus,
  notFound,
  notAvailable,
  temporaryUnavailable,
}: GetScreenProps) {
  if (temporaryUnavailable) {
    return <RefundTemporarilyUnavailable key="temporarilyUnavailable" />;
  }

  if (notAvailable) {
    return <RefundUnavailable key="notAvailable" />;
  }

  if (!refundStatus && notFound) {
    return <RefundNotFound key="notFound" />;
  }

  switch (refundStatus) {
    case RefundStatus.Processing:
      return <ProcessingRefund key="processing" />;
    case RefundStatus.Accepted:
      return <ProcessingRefund key="processing" />;
    case RefundStatus.Proposed:
      return <RefundRequest key="refundRequest" />;
    case RefundStatus.Complete:
      return <RefundComplete key="completed" />;
  }
}

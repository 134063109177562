import { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react';
import useResponsive, { SCREEN_SIZE } from '../useResponsive';

const DEFAULT_ELEMENT = 'div';

type Spacings =
  | '0'
  | '2'
  | '4'
  | '8'
  | '12'
  | '16'
  | '20'
  | '24'
  | '32'
  | '40'
  | '48'
  | '56'
  | '64'
  | '72'
  | '96'
  | '104'
  | '128'
  | '144'
  | '160'
  | '256'
  | 'auto';

type Breakpoints = keyof typeof SCREEN_SIZE;
type ResponsiveMargin = Partial<Record<Breakpoints, Spacings>>;
type Margin = ResponsiveMargin | Spacings;

export type BoxStyleProps = {
  ml?: Margin;
  mr?: Margin;
  mt?: Margin;
  mb?: Margin;
};

export type BoxOwnProps<C> = {
  as?: C;
  className?: string;
  children?: ReactNode;
  'data-test-id'?: string;
} & BoxStyleProps;

export type BoxProps<C extends ElementType = typeof DEFAULT_ELEMENT> =
  BoxOwnProps<C> & ComponentPropsWithoutRef<C>;

export default function Box<C extends ElementType = typeof DEFAULT_ELEMENT>({
  className,
  as,
  mt,
  mb,
  mr,
  ml,
  'data-test-id': dataTestId,
  children,
  style,
  ...otherProps
}: BoxProps<C>): JSX.Element {
  const Component = as || DEFAULT_ELEMENT;

  const screenSize = useResponsive();

  const getResponsiveMarginValue = (obj: ResponsiveMargin) => {
    const bpsInOrder = Object.keys(obj).sort((a, b) => {
      return SCREEN_SIZE[b as Breakpoints] - SCREEN_SIZE[a as Breakpoints];
    });

    const currentBp = bpsInOrder.find((bp) => {
      return (
        screenSize === SCREEN_SIZE[bp as Breakpoints] ||
        screenSize > SCREEN_SIZE[bp as Breakpoints]
      );
    });

    if (currentBp) {
      const marginValue = obj[currentBp as Breakpoints];
      return marginValue === 'auto'
        ? 'auto'
        : `var(--u-spacing-${marginValue})`;
    }

    return undefined;
  };

  const getSingleMarginValue = (value: string) =>
    value === 'auto' ? 'auto' : `var(--u-spacing-${value})`;

  const getMarginValue = (value?: Margin) =>
    value
      ? typeof value === 'string'
        ? getSingleMarginValue(value)
        : getResponsiveMarginValue(value)
      : undefined;

  const marginTop = getMarginValue(mt);
  const marginRight = getMarginValue(mr);
  const marginBottom = getMarginValue(mb);
  const marginLeft = getMarginValue(ml);

  return (
    <Component
      className={className}
      style={{ marginTop, marginBottom, marginLeft, marginRight, ...style }}
      data-test-id={dataTestId}
      {...otherProps}
    >
      {children}
    </Component>
  );
}

import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps {
  container?: HTMLElement;
}

export default function Portal({
  children,
  container = document.body,
}: PropsWithChildren<PortalProps>): JSX.Element {
  return createPortal(children, container);
}

import cx from 'classnames';
import Box, { BoxStyleProps } from '../Box';
import Container from '../Container';
import Logo from '../Logo';
import Text from '../Text';

import { ReactComponent as BookSvg } from '@frontend/icons/book.svg';
import { ReactComponent as CommentSvg } from '@frontend/icons/comment-2-question.svg';

import styles from './Footer.module.css';
import Icon from '../Icon/Icon';

interface FooterProps extends BoxStyleProps {
  className?: string;
}

export default function Footer({
  className,
  ...otherProps
}: FooterProps): JSX.Element {
  return (
    <Box as="footer" className={cx(styles['base'], className)} {...otherProps}>
      <Container>
        <div className={styles['inner']}>
          <Logo />
          <nav className={styles['navigation']}>
            <Text
              className={styles['link']}
              as="a"
              href="https://support.xmoney.com/en/"
              target="_blank"
              rel="noopener noreferrer"
              weight="600"
              size="tiny-callout"
              color="white"
              decoration="none"
            >
              <Icon component={<CommentSvg />} size={16} />
              Help Center
            </Text>
            <Text
              className={styles['link']}
              as="a"
              href="https://xmoney.com/legal-acknowledgement"
              target="_blank"
              rel="noopener noreferrer"
              weight="600"
              size="tiny-callout"
              color="white"
              decoration="none"
            >
              <Icon component={<BookSvg />} size={16} />
              Our Policies
            </Text>
          </nav>
        </div>
      </Container>
    </Box>
  );
}

import { motion, Variants } from 'framer-motion';
import { forwardRef, PropsWithChildren, Ref } from 'react';

import {
  AnimationDirection,
  usePhoneMockupLayoutContext,
} from '../PhoneMockupLayout';

interface PhoneMockupAnimationProps {
  className?: string;
  onAnimationComplete?: () => void;
}

const PhoneMockupAnimation = forwardRef(
  (
    {
      className,
      children,
      onAnimationComplete,
    }: PropsWithChildren<PhoneMockupAnimationProps>,
    ref: Ref<HTMLDivElement>
  ) => {
    const { animationDirection } = usePhoneMockupLayoutContext();

    const easeFunction = [0.8, 0, 0.2, 1];

    const variants: Variants = {
      enter: (direction: AnimationDirection) => {
        if (direction === 'initial') {
          return {};
        }

        return {
          x: direction === 'backwards' ? '-120px' : '120px',
          opacity: 0,
        };
      },
      entered: () => {
        return {
          x: 0,
          opacity: 1,
          transitionEnd: {
            x: 0,
          },
        };
      },
      exit: (direction: AnimationDirection) => {
        return {
          x: direction === 'backwards' ? '120px' : '-120px',
          opacity: 0,
          transition: {
            ease: easeFunction,
            duration: 0.25,
          },
        };
      },
    };

    return (
      <motion.div
        className={className}
        onAnimationComplete={onAnimationComplete}
        variants={variants}
        initial="enter"
        exit="exit"
        animate="entered"
        transition={{ duration: 0.25, ease: easeFunction }}
        custom={animationDirection}
        ref={ref}
      >
        {children}
      </motion.div>
    );
  }
);

export default PhoneMockupAnimation;

import { ReactNode } from 'react';

import Box, { BoxStyleProps } from '../Box';

import styles from './IconOverlap.module.css';

interface IconOverlapProps extends BoxStyleProps {
  mainIcon: ReactNode;
  overlapIcon: ReactNode;
  size?: number;
}

const IconOverlap = ({
  mainIcon,
  overlapIcon,
  size = 32,
  ...boxProps
}: IconOverlapProps) => {
  return (
    <Box className={styles['base']} {...boxProps}>
      <Box
        className={styles['main-icon-wrapper']}
        style={{ width: size, height: size }}
      >
        {mainIcon}
      </Box>
      <Box
        className={styles['overlap-icon-wrapper']}
        style={{ width: size / 2, height: size / 2 }}
      >
        {overlapIcon}
      </Box>
    </Box>
  );
};

export default IconOverlap;

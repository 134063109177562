import cx from 'classnames';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementType,
  PropsWithChildren,
} from 'react';

import Box from '../Box';
import { BoxStyleProps } from '../Box/Box';

import styles from './UnstyledButton.module.css';

const DEFAULT_ELEMENT = 'button';

interface UnstyledButtonOwnProps<C> {
  as?: C;
  className?: string;
};

export type UnstyledButtonProps<
  C extends ElementType = typeof DEFAULT_ELEMENT
> = UnstyledButtonOwnProps<C> & ComponentPropsWithoutRef<C> & BoxStyleProps;

export default function UnstyledButton<
  C extends ElementType = typeof DEFAULT_ELEMENT
>({
  as,
  className,
  children,
  ...otherProps
}: PropsWithChildren<UnstyledButtonProps<C>>): JSX.Element {
  const Component = as || DEFAULT_ELEMENT;

  return (
    <Box
      as={Component}
      className={cx(styles['base'], className)}
      {...otherProps}
    >
      {children}
    </Box>
  );
}

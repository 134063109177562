import { PropsWithChildren } from 'react';
import cx from 'classnames';
import Icon from '../Icon';

import { ReactComponent as CrossSvg } from '@frontend/icons/cross.svg';
import { ReactComponent as BookSvg } from '@frontend/icons/book.svg';
import { ReactComponent as CommentSvg } from '@frontend/icons/comment-2-question.svg';

import styles from './MobileNavMenu.module.css';
import Logo from '../Logo';
import Box, { BoxStyleProps } from '../Box';
import Text from '../Text';
import Center from '../Center';
import UnstyledButton from '../UnstyledButton/UnstyledButton';

interface MobileNavMenuProps extends BoxStyleProps {
  className?: string;
  onRequestClose?: () => void;
}

export default function MobileNavMenu({
  className,
  children,
  onRequestClose,
  ...otherProps
}: PropsWithChildren<MobileNavMenuProps>): JSX.Element {
  return (
    <Box className={cx(styles['base'], className)} {...otherProps}>
      <div className={styles['container']}>
        <div className={styles['header']}>
          <UnstyledButton
            className={styles['close-button']}
            type="button"
            aria-label="close"
            onClick={onRequestClose}
          >
            <Icon component={<CrossSvg />} size={24} />
          </UnstyledButton>
        </div>
        <Box className={styles['nav']} as="nav">
          <Box as="nav" className={styles['nav-list']} role="navigation">
            <Text
              className={styles['link']}
              as="a"
              href="https://support.xmoney.com/en/"
              target="_blank"
              rel="noopener noreferrer"
              weight="600"
              size="small-callout"
              color="white"
              decoration="none"
            >
              <Icon component={<CommentSvg />} size={20} />
              Help Center
            </Text>
            <Text
              className={styles['link']}
              as="a"
              href="https://xmoney.com/legal-acknowledgement"
              target="_blank"
              rel="noopener noreferrer"
              weight="600"
              size="small-callout"
              color="white"
              decoration="none"
            >
              <Icon component={<BookSvg />} size={20} />
              Our Policies
            </Text>
          </Box>
        </Box>
        <Center direction="column">
          <div className={styles['content']}>{children}</div>
          <Logo width={128} />
        </Center>
      </div>
    </Box>
  );
}

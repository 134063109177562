import { ReactNode, createContext, useContext, useState } from 'react';

export type AnimationDirection = 'backwards' | 'forwards' | 'initial';

interface PhoneMockupLayoutContext {
  animationDirection?: AnimationDirection;
  setAnimationDirection: (direction: 'backwards' | 'forwards') => void;
}

const PhoneMockupLayoutContext = createContext<PhoneMockupLayoutContext>({
  animationDirection: undefined,
  setAnimationDirection: () => undefined,
});

const PhoneMockupLayoutProvider = ({ children }: { children: ReactNode }) => {
  const [animationDirection, setAnimationDirection] =
    useState<AnimationDirection>('initial');

  return (
    <PhoneMockupLayoutContext.Provider
      value={{
        animationDirection,
        setAnimationDirection,
      }}
    >
      {children}
    </PhoneMockupLayoutContext.Provider>
  );
};

const usePhoneMockupLayoutContext = () => {
  const context = useContext(PhoneMockupLayoutContext);

  if (context === undefined) {
    throw new Error(
      'usePhoneMockupLayoutContext must be used within a PhoneMockupLayoutProvider'
    );
  }

  return context;
};

export { PhoneMockupLayoutProvider, usePhoneMockupLayoutContext };

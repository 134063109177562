import cx from 'classnames';
import React, {
  PropsWithChildren,
  isValidElement,
  ReactNode,
  ReactElement,
} from 'react';
import Box, { BoxStyleProps } from '../Box';

import styles from './WidgetHeader.module.css';

export interface WidgetHeaderProps extends BoxStyleProps {
  className?: string;
  countdownBar?: ReactNode;
  color?: 'default' | 'warning' | 'success' | 'error' | 'neutral';
}

export default function WidgetHeader({
  className,
  children,
  countdownBar,
  color = 'default',
  ...otherProps
}: PropsWithChildren<WidgetHeaderProps>): JSX.Element {
  return (
    <Box
      className={cx(
        styles['base'],
        [styles[`base--color-${color}`]],
        className
      )}
      {...otherProps}
    >
      {children}
      {countdownBar &&
        isValidElement(countdownBar) &&
        React.cloneElement(countdownBar as ReactElement, {
          className: styles['countdown-bar'],
        })}
    </Box>
  );
}

import { ReactComponent as MenuSvg } from '@frontend/icons/menu.svg';
import { BoxStyleProps } from '../Box';

import Icon from '../Icon';
import UnstyledButton from '../UnstyledButton';

export interface HamburgerProps extends BoxStyleProps {
  className?: string;
  onClick?: () => void;
}

export default function Hamburger({
  className,
  onClick,
  ...otherProps
}: HamburgerProps): JSX.Element {
  return (
    <UnstyledButton
      className={className}
      type="button"
      onClick={onClick}
      {...otherProps}
    >
      <Icon color="white" size={24} component={<MenuSvg />} />
    </UnstyledButton>
  );
}

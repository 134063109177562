import { create } from 'zustand';

interface AppStore {
  isAuthenticated: boolean;
  setIsAuthenticated: (state: boolean) => void;
  isRefundDisabled: boolean;
  setRefundDisabled: (state: boolean) => void;
  isRefundCurrencyDisabled: boolean;
  setRefundCurrencyDisabled: (state: boolean) => void;
}

export const useAppStore = create<AppStore>((set) => ({
  isAuthenticated: false,
  setIsAuthenticated: (newState) => set(() => ({ isAuthenticated: newState })),
  isRefundDisabled: false,
  setRefundDisabled: (newState) => set(() => ({ isRefundDisabled: newState })),
  isRefundCurrencyDisabled: false,
  setRefundCurrencyDisabled: (newState) =>
    set(() => ({ isRefundCurrencyDisabled: newState })),
}));

import cx from 'classnames';
import { ReactNode } from 'react';
import Box, { BoxStyleProps } from '../../Box';

import Loader from '../../Loader';
import Text from '../../Text';

import styles from './WidgetHeaderContent.module.css';

interface WidgetHeaderContentProps extends BoxStyleProps {
  className?: string;
  title?: ReactNode;
  rightSlot?: ReactNode;
  loading?: boolean;
}

export default function WidgetHeaderContent({
  className,
  title,
  rightSlot,
  loading,
  ...otherProps
}: WidgetHeaderContentProps): JSX.Element {
  return (
    <Box className={cx(styles['base'], className)} {...otherProps}>
      <div className={styles['title-wrapper']}>
        {typeof title === 'string' ? (
          <Text
            weight="700"
            size="small-callout"
            transform="uppercase"
            className={styles['title']}
          >
            {title}
          </Text>
        ) : (
          title
        )}
      </div>
      {loading && <Loader size={20} />}
      {!loading && rightSlot && (
        <div className={styles['right-slot-wrapper']}>{rightSlot}</div>
      )}
    </Box>
  );
}

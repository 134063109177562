import { useCallback, useEffect } from 'react';

interface UseAutoCreateProposal {
  expiresAt?: number;
  hasProposal: boolean;
  loading: boolean;
  createProposal: () => void;
}

const useAutoCreateProposal = ({
  hasProposal,
  expiresAt,
  loading,
  createProposal,
}: UseAutoCreateProposal) => {
  const getRemainingTime = useCallback(() => {
    if (!expiresAt) return 0;
    const now = Date.now();
    const expirationDate = new Date(expiresAt);
    return Math.max(0, expirationDate.getTime() - now);
  }, [expiresAt]);

  const refetchProposal = useCallback(() => {
    if (getRemainingTime() <= 0) {
      createProposal();
    }
  }, [createProposal, getRemainingTime]);

  useEffect(() => {
    if (!loading && !hasProposal) {
      createProposal();
    }
  }, [loading, hasProposal, createProposal]);

  useEffect(() => {
    if (!loading && expiresAt) {
      const timer = setTimeout(refetchProposal, getRemainingTime());
      return () => clearTimeout(timer);
    }
  }, [loading, expiresAt, refetchProposal, getRemainingTime]);
};

export default useAutoCreateProposal;

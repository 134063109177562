import { BoxStyleProps } from '@frontend/ui-library';

const extractBoxStyleProps = <T extends BoxStyleProps>(allProps: T) => {
  const { ml, mr, mt, mb, ...otherProps } = allProps;

  const boxStyleProps = { ml, mr, mt, mb };
  return [boxStyleProps, otherProps];
};

export default extractBoxStyleProps;

import cx from 'classnames';
import { PropsWithChildren } from 'react';
import Box, { BoxStyleProps } from '../Box';

import styles from './Container.module.css';

interface ContainerProps extends BoxStyleProps {
  className?: string;
  fluid?: boolean;
}

// TODO: We may consider adding other breakpoints later on.

export default function Container({
  className,
  fluid,
  children,
  ...otherProps
}: PropsWithChildren<ContainerProps>): JSX.Element {
  return (
    <Box
      className={cx(
        styles['base'],
        {
          [styles['base--fluid']]: fluid,
        },
        className
      )}
      data-test-id="container"
      {...otherProps}
    >
      {children}
    </Box>
  );
}

import cx from 'classnames';
import { ReactNode } from 'react';
import Box, { BoxStyleProps } from '../../Box';

import Text from '../../Text';
import { ReactComponent as QuestionIcon } from '@frontend/icons/question.svg';

import styles from './WidgetHeaderTicker.module.css';
import Tooltip, { TooltipPlacement } from '../../Tooltip/Tooltip';
import Icon from '../../Icon/Icon';
import useResponsive, { SCREEN_SIZE } from '../../useResponsive/useResponsive';

interface WidgetHeaderTickerProps extends BoxStyleProps {
  className?: string;
  value?: string;
  ticker?: ReactNode;
  tooltipContent?: ReactNode;
  mobileTooltipPlacement?: TooltipPlacement;
}

export default function WidgetHeaderTicker({
  className,
  ticker,
  value,
  tooltipContent,
  mobileTooltipPlacement,
  ...otherProps
}: WidgetHeaderTickerProps): JSX.Element {
  const screenSize = useResponsive();

  return (
    <Box className={cx(styles['base'], className)} {...otherProps}>
      <div className={styles['ticker-image-wrapper']}>{ticker}</div>
      <Text size="small" color="black">
        {value}
      </Text>
      {tooltipContent && (
        <Tooltip
          outerClassName={styles['tooltip-icon']}
          placement={
            SCREEN_SIZE.XS === screenSize
              ? mobileTooltipPlacement
                ? mobileTooltipPlacement
                : 'bottom'
              : 'top'
          }
          content={tooltipContent}
        >
          <Icon component={<QuestionIcon />} size={16} color="neutral-500" />
        </Tooltip>
      )}
    </Box>
  );
}

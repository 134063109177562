import cx from 'classnames';

import Icon, { IconProps } from '../Icon';
import Box, { BoxStyleProps, extractBoxStyleProps } from '../Box';

import styles from './Loader.module.css';

import { ReactComponent as LoadingSvg } from '@frontend/icons/loading.svg';

interface LoaderProps extends Omit<IconProps, 'component'>, BoxStyleProps {
  className?: string;
}

export default function Loader({
  className,
  ...otherProps
}: LoaderProps): JSX.Element {
  const [boxStyleProps, iconProps] = extractBoxStyleProps(otherProps);
  return (
    <Icon
      className={cx(styles['base'], className)}
      component={<Box {...boxStyleProps} as={LoadingSvg} />}
      {...iconProps}
    />
  );
}

import { ReactNode, useRef, useState } from 'react';
import { ToastProps } from '../Toast';
import ToastContext from './ToastContext';

interface ToastProviderProps {
  children: ReactNode;
}

export default function ToastProvider({
  children,
}: ToastProviderProps): JSX.Element {
  const idCounter = useRef(0);
  const [toastProps, setToastProps] = useState<ToastProps | null>(null);

  const close = () => {
    setToastProps(null);
  };

  const open = (props: ToastProps) => {
    setToastProps({
      ...props,
      onRequestClose: () => close(),
    });

    idCounter.current++;
  };

  return (
    <ToastContext.Provider
      value={{ open, close, key: idCounter.current, toastProps }}
    >
      {children}
    </ToastContext.Provider>
  );
}

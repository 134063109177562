import cx from 'classnames';
import { ReactNode } from 'react';

import styles from './PhoneMockupBodyLayout.module.css';

export interface PhoneMockupBodyLayoutProps {
  className?: string;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  bodyCentered?: boolean;
}

export default function PhoneMockupBodyLayout({
  className,
  header,
  body,
  footer,
  bodyCentered,
}: PhoneMockupBodyLayoutProps): JSX.Element {
  return (
    <div
      className={cx(
        styles['base'],
        { [styles['base--body-centered']]: bodyCentered },
        className
      )}
    >
      {header}
      <div className={styles['body']}>{body}</div>
      {footer}
    </div>
  );
}
